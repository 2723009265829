import React, {useEffect} from "react";
import {Table, Box, Container, Header} from "@amzn/awsui-components-react-v3";
import {useProgramSummaryData} from "src/components/sm-overview-dashboards/summary-view/program-summary-utils";
import {
    programSummaryColumnDefinitions
} from "src/components/sm-overview-dashboards/summary-view/program-summary-column-config";
import {SMOverviewAppLayout} from "src/components/sm-overview-dashboards/sm-overview-app-layout";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {UserActionsInfoBar} from "src/components/sm-overview-dashboards/common/UserActionsInfoBar";
import {DashboardRefreshTime} from "src/components/sm-overview-dashboards/common/dashboard-refresh-time";
import {FilterInput} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filterInput";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {useHistory} from "react-router-dom";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {
    getTimeRange
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {getTimeDiff} from "src/utils/sm-overview-dashboard-utils";

export const PROGRAM_SUMMARY_HEADER = 'SM Overview';
export const ProgramSummaryView = () => {
    const params = new URLSearchParams(location.search);
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || '';
    const history = useHistory();

    const {summaryData, loading, hasErrors} = useProgramSummaryData(filterQuery);

    useEffect(() => {
        if(!filterQuery){
            const filterQueryParam = {
                [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${DataStoreFields.WORKFLOW_START_TIME}`]
                    : getTimeRange(getTimeDiff(3, 'MONTH'), 0)
            };
            params.append(QueryParams.FILTER_QUERY_KEY, JSON.stringify(filterQueryParam));
            history.replace({search: params.toString()});
        }
    }, [filterQuery])

    return <>
        <Box padding={{vertical: "s", horizontal: "l"}}>
            <UserActionsInfoBar view={metricFeaturesList.page0} hasError={hasErrors}/>
            <DashboardRefreshTime />
        </Box>
        <Box padding={{vertical: "s", horizontal: "xl"}}>
            <FilterInput />
        </Box>
        <Box padding={{vertical: "xxl", horizontal: "xl"}} data-testid={'program-summary-table'}>
            <Container
                disableContentPaddings
            >
                <Table
                    items={Object.values(summaryData)}
                    // test this
                    columnDefinitions={programSummaryColumnDefinitions(filterQuery)}
                    loading={loading}
                    wrapLines
                    header={
                            <Header
                                variant={"h2"}
                            >
                                {PROGRAM_SUMMARY_HEADER}
                            </Header>
                    }
                />
            </Container>
        </Box>
    </>
}

export const ProgramSummary = () => <SMOverviewAppLayout
    content={<ProgramSummaryView />}
    metricName={metricFeaturesList.page0}
    page={metricPageNames.programSummary}
/>

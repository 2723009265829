import {
    Resources
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import {isProd} from "src/utils/env";

// List of all Program name ENUM. Don't add different name for ENUM and it's value.
// For eg, SYNERGY = "Synergy",
export enum PROGRAM_NAMES_ENUM {
    ACBP = "ACBP",
    OTS = "OTS",
    TEST = "TEST",
    SIC = "SIC",
    DC = "DC",
    BIC = "BIC",
    BrandLifecycle = "BrandLifecycle",
    SelectionGapManagement = "SelectionGapManagement",
    DTP = "DataTransformation",
    EstimatedParity = "EstimatedParity",
    WebsiteInsights = "WebsiteInsights",
    IntegTest = "IntegTest",
    StressTest = "StressTest",
    BrandArtefactEnrichment = "BrandArtefactEnrichment",
    DataStageIntegrationTest = "DataStageIntegrationTest",
    ProductCompatibilityProgram = "ProductCompatibilityProgram",
    UMS = "UMS",
    Matching = "Matching",
    SACE_SCI = "SACE_SCI",
    BrandClassifier = "BrandClassifier",
    GEM = "GEM",
    SCI = "SCI",
    SCIAudit = "SCIAudit",
    AuditBatch = "AuditBatch",
    TestAppConfig = "TestAppConfig",
    UBCSnapshotConsumption = "UBCSnapshotConsumption",
    SFASINEnrichment = "SF_ASIN_Enrichment",
    OASIS = "OASIS",
    SACE_SCI_3P = "SACE_SCI_3P",
    EBHSDigitization = "EBHSDigitization",
    Starfish = "Starfish",
    EDRI_Starfish = "EDRI_Starfish",
    DataQuality = "DataQuality"
}

// Mapping program to Recipe
export const PROGRAM_RECIPE_MAP: { [key in PROGRAM_NAMES_ENUM]: string } = {
    [PROGRAM_NAMES_ENUM.ACBP]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.OTS]: "ACBPRecipe",
    [PROGRAM_NAMES_ENUM.TEST]: "ACBPRecipe",
    [PROGRAM_NAMES_ENUM.SIC]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.DC]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.BIC]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.BrandLifecycle]: "UBCWorkflow",
    [PROGRAM_NAMES_ENUM.SelectionGapManagement]: "SelectionGapManagementRecipe",
    [PROGRAM_NAMES_ENUM.DTP]: "DataTransformation",
    [PROGRAM_NAMES_ENUM.EstimatedParity]: "ACBPRecipe",
    [PROGRAM_NAMES_ENUM.WebsiteInsights]: "ACBPRecipe",
    [PROGRAM_NAMES_ENUM.IntegTest]: "IntegTestRecipe",
    [PROGRAM_NAMES_ENUM.StressTest]: "StressTestRecipe",
    [PROGRAM_NAMES_ENUM.BrandArtefactEnrichment]: "BrandArtefactEnrichmentRecipe",
    [PROGRAM_NAMES_ENUM.DataStageIntegrationTest]: "DataStageIntegrationTestRecipe",
    [PROGRAM_NAMES_ENUM.ProductCompatibilityProgram]: "ACBPRecipe",
    [PROGRAM_NAMES_ENUM.UMS]: "UMS",
    [PROGRAM_NAMES_ENUM.Matching]: "MatchingRecipe",
    [PROGRAM_NAMES_ENUM.SACE_SCI]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.BrandClassifier]: "BrandClassifierWorkflow",
    [PROGRAM_NAMES_ENUM.GEM]: "GEMRecipe",
    [PROGRAM_NAMES_ENUM.SCI]: "SCIRecipe",
    [PROGRAM_NAMES_ENUM.SCIAudit]: "SCIAuditRecipe",
    [PROGRAM_NAMES_ENUM.AuditBatch]: "AuditBatchRecipe",
    [PROGRAM_NAMES_ENUM.TestAppConfig]: "TestAppConfig",
    [PROGRAM_NAMES_ENUM.UBCSnapshotConsumption]: "UBCSnapshotConsumptionRecipe",
    [PROGRAM_NAMES_ENUM.SFASINEnrichment]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.OASIS]: "OasisOrchestrationWorkflow",
    [PROGRAM_NAMES_ENUM.SACE_SCI_3P]: "ContinuousCatalogGenerationRecipe",
    [PROGRAM_NAMES_ENUM.EBHSDigitization]: "EBHSDigitizationRecipe",
    [PROGRAM_NAMES_ENUM.EDRI_Starfish]: "EdriRequestProcessorRecipe",
    [PROGRAM_NAMES_ENUM.Starfish]: "ContinuousCatalogGenerationRecipeV2",
    [PROGRAM_NAMES_ENUM.DataQuality]: "DataQuality"
}

// Mapping program to SubProgram
export const PROGRAM_SUBPROGRAM_MAP: { [key in PROGRAM_NAMES_ENUM]: Array<string> } = {
    [PROGRAM_NAMES_ENUM.ACBP]: ["EU HIP", "EU T1", "JP T1", "PathFinder", "Polish - PDF Extraction", "RBS", "US CL", "US HIT", "US T1", "Prefill", "Exp", "2022 - US T1", "Pilot_Multiple_Queue", "Pilot_Single_Queue", "Shopify", "PILOT_SDWD", "2023 3P", "2023 COBB", "PILOT_SDWD_2",
        "Brand-T1","COBB_1","COBB_2","COBB_3","COBB_4","COBB_5", "SACE_1", "SACE_2", "SACE_3", "SACE_4", "SACE_5", "DTP_1", "DTP_2", "DTP_3", "DTP_4", "DTP_touchless", "3P_2024", "BigBets", "Pro Brands"],
    [PROGRAM_NAMES_ENUM.OTS]: ["OT-B2B", "OT-B2C", "HY-B2B", "HY-B2C", "Tickets", "Pilot_Multiple_Queue", "Pilot_Single_Queue", "Shopify", "starfish", "BigBets"],
    [PROGRAM_NAMES_ENUM.TEST]: ["SUB_PROGRAM_TEST"],
    [PROGRAM_NAMES_ENUM.SIC]: [],
    [PROGRAM_NAMES_ENUM.DC]: [],
    [PROGRAM_NAMES_ENUM.BIC]: [],
    [PROGRAM_NAMES_ENUM.BrandLifecycle]: [],
    [PROGRAM_NAMES_ENUM.SelectionGapManagement]: ["GapComputation", "BrandMarketPlaceAttribute"],
    [PROGRAM_NAMES_ENUM.DTP]: ["COBB", "Product Knowledge", "Exp", "DTP_1", "DTP_2", "DTP_3", "DTP_4"],
    [PROGRAM_NAMES_ENUM.EstimatedParity]: ["OTS-B2C", "Pilot_Estimated_Parity", "COBB_4", "SIC", "OT-B2C"],
    [PROGRAM_NAMES_ENUM.WebsiteInsights]: [],
    [PROGRAM_NAMES_ENUM.IntegTest]: [],
    [PROGRAM_NAMES_ENUM.StressTest]: [],
    [PROGRAM_NAMES_ENUM.ProductCompatibilityProgram]: [],
    [PROGRAM_NAMES_ENUM.UMS]:[],
    [PROGRAM_NAMES_ENUM.Matching]: ["ARM", "AFM", "AIR", "AIR Simulation", "ARM Experimentation", "Estimated Coverage", "De-duper", "De-duper Simulation", "Estimated Parity", "Exp"],
    [PROGRAM_NAMES_ENUM.SACE_SCI]: ["EU HIP", "EU T1", "JP T1", "PathFinder", "Polish - PDF Extraction", "RBS", "US CL", "US HIT", "US T1", "Prefill", "Exp", "2022 - US T1", "Pilot_Multiple_Queue", "Pilot_Single_Queue", "Shopify", "PILOT_SDWD", "2023 3P", "2023 COBB", "PILOT_SDWD_2",
        "Brand-T1","COBB_1","COBB_2","COBB_3","COBB_4","COBB_5","DTP_touchless", "Pro Brands"],
    [PROGRAM_NAMES_ENUM.BrandClassifier]:["PTBR Feedback", "PTBR Overrides", "PTBR Offline"],
    [PROGRAM_NAMES_ENUM.GEM]:[],
    [PROGRAM_NAMES_ENUM.BrandArtefactEnrichment]: [],
    [PROGRAM_NAMES_ENUM.DataStageIntegrationTest]: [],
    [PROGRAM_NAMES_ENUM.SCI]:[],
    [PROGRAM_NAMES_ENUM.SCIAudit]:["Data Quality","Adhoc Audit","Deduper Audit","Model Training"],
    [PROGRAM_NAMES_ENUM.AuditBatch]:[],
    [PROGRAM_NAMES_ENUM.TestAppConfig]: [],
    [PROGRAM_NAMES_ENUM.UBCSnapshotConsumption]:[],
    [PROGRAM_NAMES_ENUM.SFASINEnrichment]: ["Brand Websites"],
    [PROGRAM_NAMES_ENUM.OASIS]: [],
    [PROGRAM_NAMES_ENUM.BrandLifecycle]: ["brandRepresentationAuditStage_ROUTINE-JPBrandRepAudit_YONAGO_SEEDS", "brandValidationStage_BPBO_SOG", "brandValidationStage_ROUTINE_ACCENTURE", "brandDeduplicationStage_ROUTINE_ADHOC_ACCENTURE", "brandValidationStage_CRITICAL_ACCENTURE", "brandDeduplicationStage_ROUTINE_ADHOC_SOG", "brandValidationStage_ROUTINE_SOG", "brandDeduplicationStage_ROUTINE_SOG", "brandDeduplicationStage_ROUTINE_ACCENTURE"],
    [PROGRAM_NAMES_ENUM.SACE_SCI_3P]: ["EU HIP", "EU T1", "JP T1", "PathFinder", "Polish - PDF Extraction", "RBS", "US CL", "US HIT", "US T1", "Prefill", "Exp", "2022 - US T1", "Pilot_Multiple_Queue", "Pilot_Single_Queue", "Shopify", "PILOT_SDWD", "2023 3P", "2023 COBB", "PILOT_SDWD_2",
        "Brand-T1","COBB_1","COBB_2","COBB_3","COBB_4","COBB_5","DTP_touchless","SACE_1","SACE_2","SACE_3","SACE_4","SACE_5"],
    [PROGRAM_NAMES_ENUM.EBHSDigitization]: ["DigitizationRoR"],
    [PROGRAM_NAMES_ENUM.Starfish]: ["BrandMonitoring", "EdriPublisher", "EdriAsinEvaluator", "EdriBrandEvaluator", "priority_us_websites"],
    [PROGRAM_NAMES_ENUM.EDRI_Starfish]: [],
    [PROGRAM_NAMES_ENUM.DataQuality]: ["Source Data Quality"]
}

export const pleaseSelectLabel: SelectProps.Option = {label: "Please Select", value: ""}

export const PROGRAM_OPTIONS_DEFINITIONS: Array<SelectProps.Option> = [pleaseSelectLabel,
    {label: PROGRAM_NAMES_ENUM.ACBP, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.ACBP]},
    {label: PROGRAM_NAMES_ENUM.OTS, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.OTS]},
    {label: PROGRAM_NAMES_ENUM.SIC, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SIC]},
    {label: PROGRAM_NAMES_ENUM.DC, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.DC]},
    {label: PROGRAM_NAMES_ENUM.BIC, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.BIC]},
    {label: PROGRAM_NAMES_ENUM.BrandLifecycle, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.BrandLifecycle]},
    {label: PROGRAM_NAMES_ENUM.SelectionGapManagement, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SelectionGapManagement]},
    {label: PROGRAM_NAMES_ENUM.DTP, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.DTP]},
    {label: PROGRAM_NAMES_ENUM.EstimatedParity, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.EstimatedParity]},
    {label: PROGRAM_NAMES_ENUM.WebsiteInsights, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.WebsiteInsights]},
    {label: PROGRAM_NAMES_ENUM.ProductCompatibilityProgram, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.ProductCompatibilityProgram]},
    {label: PROGRAM_NAMES_ENUM.UMS,value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.UMS]},
    {label: PROGRAM_NAMES_ENUM.Matching, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.Matching]},
    {label: PROGRAM_NAMES_ENUM.SACE_SCI, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SACE_SCI]},
    {label: PROGRAM_NAMES_ENUM.BrandClassifier, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.BrandClassifier]},
    {label: PROGRAM_NAMES_ENUM.GEM, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.GEM]},
    {label: PROGRAM_NAMES_ENUM.SCI, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SCI]},
    {label: PROGRAM_NAMES_ENUM.SCIAudit, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SCIAudit]},
    {label: PROGRAM_NAMES_ENUM.AuditBatch, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.AuditBatch]},
    {label: PROGRAM_NAMES_ENUM.UBCSnapshotConsumption, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.UBCSnapshotConsumption]},
    {label: PROGRAM_NAMES_ENUM.BrandArtefactEnrichment, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.BrandArtefactEnrichment]},
    {label: PROGRAM_NAMES_ENUM.SFASINEnrichment, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SFASINEnrichment]},
    {label: PROGRAM_NAMES_ENUM.OASIS, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.OASIS]},
    {label: PROGRAM_NAMES_ENUM.SACE_SCI_3P, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.SACE_SCI_3P]},
    {label: PROGRAM_NAMES_ENUM.EBHSDigitization, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.EBHSDigitization]},
    {label: PROGRAM_NAMES_ENUM.Starfish, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.Starfish]},
    {label: PROGRAM_NAMES_ENUM.EDRI_Starfish, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.EDRI_Starfish]},
    {label: PROGRAM_NAMES_ENUM.DataQuality, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.DataQuality]},
    ... (isProd() ? [] : [
        {label: PROGRAM_NAMES_ENUM.IntegTest, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.IntegTest]},
        {label: PROGRAM_NAMES_ENUM.DataStageIntegrationTest, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.DataStageIntegrationTest]},
        {label: PROGRAM_NAMES_ENUM.TestAppConfig, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.TestAppConfig]},
        {label: PROGRAM_NAMES_ENUM.StressTest, value: PROGRAM_RECIPE_MAP[PROGRAM_NAMES_ENUM.StressTest]}
    ])
]

export const RESOURCES_DEFAULT: Resources = [{
    resourceType: "SWO_RECIPE_EXECUTION",
    units: 1
}]

export const TARGETED_WEBSITES: Array<string> = ["www.amazon.com", "www.amazon.ca", "www.amazon.com.mx",
    "www.amazon.com.br", "www.amazon.de", "www.amazon.fr", "www.amazon.co.uk", "www.amazon.it", "www.amazon.es",
    "www.amazon.in", "www.amazon.com.tr", "www.amazon.sa", "www.amazon.ae", "www.amazon.eg", "www.amazon.nl",
    "www.amazon.se", "www.amazon.pl", "www.amazon.com.be", "www.amazon.com.ng", "www.amazon.co.za", "www.amazon.co.jp",
    "www.amazon.com.au", "www.amazon.sg", "www.amazon.cn", "www.amazon.cl", "www.amazon.com.co", "www.amazon.ch",
    "www.amazon.at", "www.amazon.ie"]

export const VALID_RESTART_REQUEST_TIME_WINDOW: number = 300000;
export const BULK_START_CSV_FILE_LIMIT: number = 3
export const BULK_START_CSV_MAX_ROWS: number = 1000
export const BULK_START_PLUGIN_FIELD_NAME_DELIMITER: string = "."
export const CSV_CONTEXT_KEY: string = "context"
export const SELECT_DROPDOWN_VALUE_DELIMITER: string = "-"
export const CONTINUOUS_PROGRAM_RECIPE: string = "ContinuousCatalogGenerationRecipe"
export const SCHEDULE_ENABLED_PROGRAMS = [PROGRAM_NAMES_ENUM.SelectionGapManagement, PROGRAM_NAMES_ENUM.UMS, PROGRAM_NAMES_ENUM.Matching, PROGRAM_NAMES_ENUM.GEM, PROGRAM_NAMES_ENUM.SCI,PROGRAM_NAMES_ENUM.AuditBatch, PROGRAM_NAMES_ENUM.UBCSnapshotConsumption, PROGRAM_NAMES_ENUM.OASIS, PROGRAM_NAMES_ENUM.BrandLifecycle, PROGRAM_NAMES_ENUM.EBHSDigitization, PROGRAM_NAMES_ENUM.EDRI_Starfish];
export const BULK_START_ENABLED_PROGRAMS = [PROGRAM_NAMES_ENUM.ACBP, PROGRAM_NAMES_ENUM.OTS, PROGRAM_NAMES_ENUM.EstimatedParity, PROGRAM_NAMES_ENUM.WebsiteInsights, PROGRAM_NAMES_ENUM.ProductCompatibilityProgram, PROGRAM_NAMES_ENUM.SACE_SCI, PROGRAM_NAMES_ENUM.SFASINEnrichment, PROGRAM_NAMES_ENUM.SACE_SCI_3P, PROGRAM_NAMES_ENUM.Starfish, PROGRAM_NAMES_ENUM.DataQuality];
export const REPUBLISH_ALLOWED_STAGES = ["CONTINUOUS_CRAWL"]

const contextData = {
    "context.domain": "www.flipkart4.com",
    "context.program": "OTS",
    "context.subProgram": "OT-B2C",
    "context.targetWebsite": "www.amazon.com",
}

const continuousCrawlData = {
    "CONTINUOUS_CRAWL.template": "configless",
    "CONTINUOUS_CRAWL.isContinuousCrawl": true,
    "CONTINUOUS_CRAWL.crawlMeasurementMode": "AUTO",
    "CONTINUOUS_CRAWL.fetcher": "SYMBIOTE",
    "CONTINUOUS_CRAWL.crawlCompletenessS3File": "s3://",
    "CONTINUOUS_CRAWL.crawlMode": "DEFAULT",
    "CONTINUOUS_CRAWL.bindleName": "",
    "CONTINUOUS_CRAWL.bindleVersion": ""
}

const domainOnboarderData = {
    "domainOnboarder.domainType": "WEBSITE",
    "domainOnboarder.isVariantCrawlEnabled": false,
    "domainOnboarder.isLoadMoreEnabled": false,
    "domainOnboarder.isPaginationUARequired": false,
    "domainOnboarder.fetcherType": "Simple",
    "domainOnboarder.region": "NA",
    "domainOnboarder.country": "US",
    "domainOnboarder.isAnonymous": true,
    "domainOnboarder.isCountrySpecific": false,
    "domainOnboarder.domain": "testDomain",
    "domainOnboarder.seedUrl": "testSeedUrl",
    "domainOnboarder.domainRegex": "testRegex",
    "domainOnboarder.targetedWebsites": "www.amazon.com",
    "domainOnboarder.proxy": "EDAC",
    "domainOnboarder.throttle": 1,
    "domainOnboarder.flowKey": "Default-DE"
}

const setupWorkflowConfigData = {
    "setupWorkflowConfigs.setupWorkflowConfigsMode": "DEFAULT",
    "setupWorkflowConfigs.crawlMode": "DEFAULT",
    "setupWorkflowConfigs.isVariant": true,
    "setupWorkflowConfigs.skipLabelingAttributes.jsonString": "[\"color_name\"]",
    "setupWorkflowConfigs.fetcher": "SYMBIOTE",
    "setupWorkflowConfigs.brandName": "test",
    "setupWorkflowConfigs.languageTag": "en_US",
    "setupWorkflowConfigs.marketPlaceId": "1",
    "setupWorkflowConfigs.wsWorkforceArn": "arn:aws:sagemaker:us-east-2:408505725694:workteam/private-crowd/Beacon-Cogito-DS-For-Training",
    "setupWorkflowConfigs.openHexLabelingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/Accenture",
    "setupWorkflowConfigs.identityAttributesLabelingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/Accenture",
    "setupWorkflowConfigs.noOfWorkers": 1,
    "setupWorkflowConfigs.feasibilitySampleCount": 1,
    "setupWorkflowConfigs.auditSampleCount": 1,
    "setupWorkflowConfigs.assistedScrapingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/SMPO",
    "setupWorkflowConfigs.configName": ""
}

const crawlSamplingData = {
    "crawlSampling.edacRegion": "EU",
    "crawlSampling.flowKey": "Default-DE",
    "crawlSampling.isWhiteHatCrawl": true,
    "crawlSampling.skipCrawlSampling": true,
    "crawlSampling.throttlePerSecond.string": 0.196759259,
}

const crawlData = {
    "crawl.crawlMode": "DEFAULT",
    "crawl.fetcher": "SYMBIOTE",
    "crawl.template": "configless",
    "crawl.crawlCompletenessS3File": "s3://",
}

const scraperPlannerData = {
    "scraperPlanner.assistedScrapingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/SMPO",
    "scraperPlanner.identityAttributesLabelingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/SMPO",
    "scraperPlanner.openHexLabelingWorkforce": "arn:aws:sagemaker:us-east-1:136747787334:workteam/private-crowd/Beacon-Open-Hex-Cogito",
    "scraperPlanner.skipLabelingAttributes.jsonString": "[\"brand_name\"]",
    "scraperPlanner.wsAttributeList": "",
    "scraperPlanner.wsBrandName": "",
    "scraperPlanner.wsLabelingWorkforce": "arn:aws:sagemaker:us-east-1:408505725694:workteam/private-crowd/SMT-SD-ML",
    "scraperPlanner.wsMarketplace": "US",
    "scraperPlanner.isvariant": false,
    "scraperPlanner.noOfWorkers": 1,
    "scraperPlanner.feasibilitySampleCount": 50,
    "scraperPlanner.auditSampleCount": 150,
    "scraperPlanner.validateScrapeDataBeforeUECIngestion": true
}

export const SAMPLE_BULK_START_INPUT_FILE_NON_CONTINUOUS_WORKFLOW = "Sample Bulk Start Input File For Non-Continuous monitoring";
export const SAMPLE_BULK_START_INPUT_FILE_CONTINUOUS_WORKFLOW = "Sample Bulk Start Input File For Continuous monitoring"

export const getBulkStartSampleInput = (isEmpty: boolean = false, isContinuous: boolean = false):Array<{[key: string]: string | number | boolean}> => {
    if (isEmpty) return []
    return (isContinuous ? [{
        ...contextData,
        ...continuousCrawlData,
        ...domainOnboarderData,
        ...setupWorkflowConfigData,
    }] : [{
        ...contextData,
        ...domainOnboarderData,
        ...crawlData,
        ...crawlSamplingData,
        ...scraperPlannerData
    }])
}

import {v4 as uuidv4} from "uuid";
import {
    BULK_START_CSV_FILE_LIMIT,
    BULK_START_PLUGIN_FIELD_NAME_DELIMITER, CONTINUOUS_PROGRAM_RECIPE,
    CSV_CONTEXT_KEY, getBulkStartSampleInput,
    PROGRAM_NAMES_ENUM, PROGRAM_RECIPE_MAP,
    RESOURCES_DEFAULT,
    SAMPLE_BULK_START_INPUT_FILE_CONTINUOUS_WORKFLOW,
    SAMPLE_BULK_START_INPUT_FILE_NON_CONTINUOUS_WORKFLOW,
    SCHEDULE_ENABLED_PROGRAMS
} from "src/constants/workflow-instance";
import {
    BulkStartRecipeExecutionsResult,
    Map
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import * as Papa from "papaparse";
import {ParseResult} from "papaparse";
import {BulkStartWorkflowInstanceRow, BulkStartWorkflowInstanceRowMap} from "src/interfaces/start-workflow-instance";
import {toast} from "react-toastify";
import {DomainTracker} from "src/model/DomainTracker";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";

/**
 * Util methods for workflow instances.
 */
export class WorkflowInstanceUtil {
    /**
     * Download bulk start workflow instance error file
     * @param fileContent
     * @param fileName
     */
    static downloadBulkStartErrorFile = async (fileContent: Array<{[key: string]: string | number | boolean}>, fileName = "Bulk-Start-Error-Report") => {
        const fileBlob = new Blob([Papa.unparse(fileContent, {
            header: true
        })], {type: "text/csv"})

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(fileBlob);
        link.download = `${fileName}-${+new Date()}.csv`;
        link.click();
    };

    /**
     * Convert JSON Array to CSV Map
     * @param inputJsonArray
     * @param sliceLimit
     * @param selectedProgram
     */
    static convertJSONArrayToCSV = (inputJsonArray: Array<any>, sliceLimit: number, selectedProgram?: string): BulkStartWorkflowInstanceRowMap => {
        const numberOfSlices: number = Math.ceil(inputJsonArray.length / sliceLimit)
        let i = 0;
        const convertedFile: { [key: string]: Array<any> } = {}
        const fileBlobs: { [key: string]: Array<BulkStartWorkflowInstanceRow> } = {}
        for (i; i < numberOfSlices; i++) {
            const startPoint = i * sliceLimit
            const endPoint = startPoint + sliceLimit
            convertedFile[`part${i}`] = inputJsonArray.slice(startPoint, endPoint)

            const rows: Array<BulkStartWorkflowInstanceRow> = []

            convertedFile[`part${i}`].forEach(cFile => {
                const recipeExecutionInput = WorkflowInstanceUtil.convertFlatJsonToPluginMap(cFile)
                const recipeExecutionInputContext = JSON.parse(JSON.stringify(recipeExecutionInput[CSV_CONTEXT_KEY] || {}))
                delete recipeExecutionInput[CSV_CONTEXT_KEY]
                const row: BulkStartWorkflowInstanceRow = {
                    requestId: uuidv4(),
                    recipeExecutionInput: JSON.stringify(recipeExecutionInput),
                    resourceAllocation: JSON.stringify(RESOURCES_DEFAULT),
                    program: selectedProgram || "",
                    subProgram: recipeExecutionInputContext["subProgram"],
                    targetWebsite: recipeExecutionInputContext["targetWebsite"],
                    websiteId: recipeExecutionInputContext["websiteId"],
                    domain: recipeExecutionInputContext["domain"],
                }

                rows.push(row)
            })

            fileBlobs[`part${i}`] = rows
        }
        return fileBlobs
    }

    static convertFlatJsonToPluginMap = (jsonInput: Map): { [key: string]: Map } => {
        const outputMap: { [key: string]: Map } = {}
        Object.entries(jsonInput).forEach(([key1, value1]) => {
            if(value1 != null) {
                const columnNameSplit = key1.split(BULK_START_PLUGIN_FIELD_NAME_DELIMITER)
                if (outputMap[columnNameSplit[0]] === undefined) {
                    outputMap[columnNameSplit[0]] = {
                        [columnNameSplit[1]]: WorkflowInstanceUtil.convertToSpecifiedType(columnNameSplit, value1)
                    }
                } else {
                    outputMap[columnNameSplit[0]] = {
                        ...outputMap[columnNameSplit[0]],
                        [columnNameSplit[1]]: WorkflowInstanceUtil.convertToSpecifiedType(columnNameSplit, value1)
                    }
                }
            }
        })
        return outputMap;
    }

    /**
    * Utility method to convert the input fields to specified type.value
    * CSV can't support complex types like array, json etc. These values will have comma(,) and CSV separates the fields by comma delimitter.
    * Hence, users can specify the value and corresponding type as stage.value.type. This method will convert the given value to specified type.
    *
    * Note: The existing way of dynamicTyping is used to override default type casting where as this is a way of specifying explicitly type conversion.
            True that we can achieve ".string" for numeric values using the same way, we will not be using the library features.
            Given that, we may/not not support array type as a platform, keeping the functionalities different.

    * @param columnNameSplit array containing the stage-name, field name and optional type
    * @param value value for the field.
    * @return converted/original value based on type specified.
    */
    static convertToSpecifiedType = (columnNameSplit: Array<string>, value: any) : any => {
        if(columnNameSplit.length == 3) {
            switch(columnNameSplit[2]) {
                case "jsonString": {
                    return JSON.parse(value.toString())
                }
                default: return typeof value === 'string' || value instanceof String ? value.trim() : value;
            }
        }
        return typeof value === 'string' || value instanceof String ? value.trim() : value;
    }

    /**
     * Convert plugin name(pluginname.columnName) to flat json
     * @param inputMap
     */
    static convertPluginMapToFlatJson = (inputMap: { [key: string]: Map }): Map => {
        const outputMap: Map = {}
        Object.entries(inputMap).forEach(([pluginName, pluginFields]) => {
            Object.entries(pluginFields).forEach(([fieldName, fieldValue]) => {
                outputMap[`${pluginName}${BULK_START_PLUGIN_FIELD_NAME_DELIMITER}${fieldName}`] = fieldValue
            })
        })
        return outputMap;
    }

    /**
     * Call Bulk start API and aggregate failure response.
     * @param bulkStartRequestPromises
     * @param bulkStartWorkflowInstanceRowMap
     * @param setFailedFileContent
     * @param setLoading
     */
    static bulkStartInstance(bulkStartRequestPromises: Array<Promise<BulkStartRecipeExecutionsResult>>, bulkStartWorkflowInstanceRowMap: BulkStartWorkflowInstanceRowMap,
                             setFailedFileContent: Function, setLoading: Function) {
        const errorFileContent: Map = {}

        Promise.allSettled(bulkStartRequestPromises)
            .then((results) => {
                results.forEach(result => {
                    if (result.status === "fulfilled") {
                        Object.entries(result.value.failedRequests || {}).forEach(([requestId, errorReason]) => {
                            errorFileContent[requestId] = errorReason
                        })
                    } else {
                        errorFileContent[uuidv4()] = result.reason
                    }
                })
            })
            .catch(error => {
                toast.error(JSON.stringify(error))
                console.error("Error while calling bulk start api", error)
            })
            .finally(() => {
                if (Object.entries(errorFileContent).length == 0) {
                    toast.success("All rows processed successfully")
                }
                const downloadFileContent: Array<Map> = []
                Object.entries(bulkStartWorkflowInstanceRowMap).forEach(([key, inputRows]) => {
                    inputRows.forEach(inputRow => {
                        if (undefined !== errorFileContent[inputRow.requestId]) {
                            const recipeExecutionInputJson = WorkflowInstanceUtil.convertPluginMapToFlatJson(JSON.parse(inputRow.recipeExecutionInput))
                            downloadFileContent.push(
                                {
                                    ...recipeExecutionInputJson,
                                    requestId: inputRow.requestId,
                                    errorReason: errorFileContent[inputRow.requestId],
                                }
                            )

                        }
                    })
                })
                setFailedFileContent(downloadFileContent)
                setLoading(false)
                if (downloadFileContent.length > 0) WorkflowInstanceUtil.downloadBulkStartErrorFile(downloadFileContent)

            })
    }

    /**
     * Handing CSV to JSON parse complete
     * @param results
     * @param programSelectedOption
     * @param setTotalNumberOfRows
     * @param setFailedFileContent
     * @param setLoading
     */
    static handleParseComplete(results: ParseResult<any>, programSelectedOption: SelectProps.Option, setTotalNumberOfRows: Function,
                               setFailedFileContent: Function, setLoading: Function, isContinuous?: boolean) {
        setTotalNumberOfRows(results.data.length)
        const splittedCSV = WorkflowInstanceUtil.convertJSONArrayToCSV(results.data, BULK_START_CSV_FILE_LIMIT, programSelectedOption.label)
        const bulkStartUploadS3Promise: Array<Promise<string>> = []
        const bulkStartWIPromise: Array<Promise<BulkStartRecipeExecutionsResult>> = []
        const dt = new DomainTracker()
        let isSuccessfullUpload: boolean = true
        Object.entries(splittedCSV).forEach(([key, value]) => {
            bulkStartUploadS3Promise.push(dt.bulkStartUploadS3(new Blob([
                Papa.unparse(value, {
                    header: true
                })
            ], {type: "text/csv"})))
        })

        Promise.allSettled(bulkStartUploadS3Promise)
            .then((results) => {
                if (bulkStartUploadS3Promise.length === results.filter(result => result.status === "rejected").length) {
                    toast.error("Failed to upload file to s3. Please reload the page and try again")
                    isSuccessfullUpload = false
                    setLoading(false)
                    return
                }
                results.forEach((result, index) => {
                    if (result.status === "fulfilled") {
                        const recipeId = WorkflowInstanceUtil.getProgramRecipe(programSelectedOption.label || "", isContinuous);
                        bulkStartWIPromise.push(dt.bulkStartWorkflowInstance(result.value,
                            programSelectedOption.label || "",
                            recipeId
                        ))
                    } else {
                        toast.error(result.reason);
                    }
                });
            })
            .catch((error) => {
                toast.error(JSON.stringify(error));
            }).finally(() => {
            if (isSuccessfullUpload) {
                WorkflowInstanceUtil.bulkStartInstance(bulkStartWIPromise, splittedCSV, setFailedFileContent, setLoading)
            }
        })
    }

    static isDomainFieldVisible(selectedProgram: SelectProps.Option): boolean {
        return selectedProgram.value !== "" &&
            [
                PROGRAM_NAMES_ENUM.ACBP.valueOf(),
                PROGRAM_NAMES_ENUM.OTS.valueOf(),
                PROGRAM_NAMES_ENUM.SIC.valueOf(),
                PROGRAM_NAMES_ENUM.DC.valueOf(),
                PROGRAM_NAMES_ENUM.BIC.valueOf(),
                PROGRAM_NAMES_ENUM.DTP.valueOf(),
                PROGRAM_NAMES_ENUM.EstimatedParity.valueOf(),
                PROGRAM_NAMES_ENUM.WebsiteInsights.valueOf(),
                PROGRAM_NAMES_ENUM.IntegTest.valueOf(),
                PROGRAM_NAMES_ENUM.ProductCompatibilityProgram.valueOf(),
                PROGRAM_NAMES_ENUM.SACE_SCI.valueOf(),
                PROGRAM_NAMES_ENUM.SFASINEnrichment.valueOf(),
                PROGRAM_NAMES_ENUM.SACE_SCI_3P.valueOf(),
                PROGRAM_NAMES_ENUM.Starfish.valueOf()
            ].includes(selectedProgram.label || "")
    }

    static enableSubProgramField(subProgramOptions: Array<SelectProps.Option>): boolean {
        return  subProgramOptions.length > 0;
    }

    static isCompetitorProgramSelected(selectedProgram: SelectProps.Option): boolean {
        return [PROGRAM_NAMES_ENUM.SIC.valueOf(), PROGRAM_NAMES_ENUM.DC.valueOf(), PROGRAM_NAMES_ENUM.BIC.valueOf()].includes(selectedProgram?.label || "")
    }

    static isProgramScheduleDisabled(selectedProgram: SelectProps.Option): boolean {
        return SCHEDULE_ENABLED_PROGRAMS.filter((program) =>
            program.valueOf() === (selectedProgram?.label || "")).length === 0
    }

    static isContinuousProgramVariantSupported(selectedProgram: string|undefined): boolean {
        return [
                PROGRAM_NAMES_ENUM.OTS.valueOf(),
                PROGRAM_NAMES_ENUM.EstimatedParity.valueOf(),
                PROGRAM_NAMES_ENUM.WebsiteInsights.valueOf(),
                PROGRAM_NAMES_ENUM.SACE_SCI.valueOf()
            ].includes(selectedProgram || "")
    }

    static isContinuousProgramVariantCheckEnabled(selectedProgram: SelectProps.Option): boolean {
        return [
            PROGRAM_NAMES_ENUM.WebsiteInsights.valueOf()
        ].includes(selectedProgram?.label || "")
    }

    static getProgramRecipe(selectedProgram: string, isContinuous?: boolean): string {
        return isContinuous && WorkflowInstanceUtil.isContinuousProgramVariantSupported(selectedProgram)
            ? CONTINUOUS_PROGRAM_RECIPE : PROGRAM_RECIPE_MAP[selectedProgram as PROGRAM_NAMES_ENUM];
    }

    static handleDownloadSampleLinkFollow (isContinuous: boolean=false) {
        const fileName = isContinuous ? SAMPLE_BULK_START_INPUT_FILE_CONTINUOUS_WORKFLOW : SAMPLE_BULK_START_INPUT_FILE_NON_CONTINUOUS_WORKFLOW;
        WorkflowInstanceUtil.downloadBulkStartErrorFile(getBulkStartSampleInput(false, isContinuous), fileName);
    }

    static trimObject(workflowInput: object) {
        var trimmedWorkflowInput = JSON.stringify(workflowInput, (key, value) => {
            if (typeof value === 'string' || value instanceof String) {
                return value.trim();
            }
            return value;
        });
        return trimmedWorkflowInput;
    }
}


export const convertCamelCaseStringToWords = (camelCase: string) => camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const checkIfInputIsCamelCase = (input: string) => !input.match(/^[A-Z]|[\s_-]/g);

export const convertCharacterSeperatedStringToWords = (characterSeperatedString: string) => {
    const lowerCase = characterSeperatedString.toLowerCase();
    const words = lowerCase.match(/[A-Za-z0-9][^_|^-]*/g) || [];
    return words.join(" ").replace(/\b\w/g, match => match.toUpperCase());
}

export const fixStringCasing = (caseString: string) => {
    return checkIfInputIsCamelCase(caseString) ? convertCamelCaseStringToWords(caseString)
        : convertCharacterSeperatedStringToWords(caseString)
}

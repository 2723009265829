import React from 'react';
import {Link, Popover} from '@amzn/awsui-components-react-v3';
import {Link as LinkRouter} from 'react-router-dom';
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {
    DataStoreFields, FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {
    mergeFiltersToFilterQuery
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";

export const ExternalDashboardLink: React.FunctionComponent<{displayNode: React.ReactNode | null, externalLink: string}> = ({displayNode, externalLink}) => {
    return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <LinkRouter
            to={{pathname: externalLink}} target="_blank">
            <Link external>{displayNode}</Link>
        </LinkRouter>
        {!displayNode && <p style={{marginLeft: '4px'}}>
            <Popover
                dismissButton={true}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <p>
                        {'The metrics number will be visible on dashboard soon'}
                    </p>
                }
            >
                <Link variant="info">Info</Link>
            </Popover>
        </p>}
    </div>
}

export const InternalDiveDeepLink: React.FunctionComponent<{displayNode: React.ReactNode, filterQuery: string, route: string, program: string}>
    = ({displayNode, filterQuery, route, program}) => {
    return <>
        <LinkRouter
            to={`${route}?${QueryParams.FILTER_QUERY_KEY}=${mergeFiltersToFilterQuery(filterQuery, {[`${QueryVerb.MUST}::${FilterOperation.MATCH_ANY}:${DataStoreFields.PROGRAM}`]: program})}`}>
            <Link external>{displayNode}</Link>
        </LinkRouter>
    </>
}

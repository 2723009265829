import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {unifiedDashboardAPIConfig} from "src/utils/api-gateway/swo-ud-api-mappings";
import {getStage} from "src/utils/env";
import {APPLICATION_NAME} from "src/utils/app-constants";
import {getUserAlias} from "src/utils/cookie-helper";
import {
    GetProgramFailureDetailRequest,
    GetStageFailureDetailRequest,
    GetLastUpdatedTimeRequest,
    GetRunningWorkflowDetailRequest
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

export class SMSummary {
    private unifiedDashboardServiceLambda: SMSWOUnifiedDashboardServiceLambda;

    constructor() {
        const unifiedDashboardConfig = unifiedDashboardAPIConfig(getStage());
        this.unifiedDashboardServiceLambda = new SMSWOUnifiedDashboardServiceLambda({
            endpoint: `https://${unifiedDashboardConfig.endpoint}/${unifiedDashboardConfig.stage}`,
            region: unifiedDashboardConfig.region
        })
    }

    async getSummaryData(summaryType: 'PROGRAM'| 'CYCLE_TIME' | 'WORKITEM_AND_FAILURE', filterQuery: string) {
        let summaryRequest = {
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias(),
            filterQuery: filterQuery
        }
        switch (summaryType) {
            case 'PROGRAM':
                return this.unifiedDashboardServiceLambda.getProgramSummary(summaryRequest).promise();
            case 'CYCLE_TIME':
                return this.unifiedDashboardServiceLambda.getCycleTimeSummary(summaryRequest).promise();
            case 'WORKITEM_AND_FAILURE':
                return this.unifiedDashboardServiceLambda.getWorkItemAndFailureSummary(summaryRequest).promise();
        }
    }

    async getProgramFailureDetailData(
        requestParams: Omit<GetProgramFailureDetailRequest, 'requesterSystemType'|'requesterUserName'>
    ) {
        const programFailureDetailRequest: GetProgramFailureDetailRequest = {
            ...requestParams,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        return this.unifiedDashboardServiceLambda.getProgramFailureDetail(programFailureDetailRequest).promise();
    }

    async getStageFailureDetailData(
        requestParams: Omit<GetStageFailureDetailRequest, 'requesterSystemType'|'requesterUserName'>
    ) {
        const stageFailureDetailRequest: GetStageFailureDetailRequest = {
            ...requestParams,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        return this.unifiedDashboardServiceLambda.getStageFailureDetail(stageFailureDetailRequest).promise();
    }

    async getDashboardRefreshTime() {
        const lastUpdatedTimeRequest: GetLastUpdatedTimeRequest = {
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        return this.unifiedDashboardServiceLambda.getLastUpdatedTime(lastUpdatedTimeRequest).promise();
    }

    async getProgramRunningDetail(requestParams: Omit<GetRunningWorkflowDetailRequest, 'requesterSystemType'|'requesterUserName'>) {
        const programRunningDetailRequest: GetProgramFailureDetailRequest = {
            ...requestParams,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        return this.unifiedDashboardServiceLambda.getRunningWorkflowDetail(programRunningDetailRequest).promise();
    }
}

export const dashboardTestdata = {
    summaryData: [
        {
            program: 'ACBP',
            onboardedWorkflowInstances: 2000,
            runningWorkflowInstances: 200,
            failedWorkflowInstances: 100,
            sucessfullWorkflowInstances: 100,
            stoppedWorkflowInstances: 10
        },
        {
            program: 'SIC',
            onboardedWorkflowInstances: 100,
            runningWorkflowInstances: 100,
            failedWorkflowInstances: 60,
            sucessfullWorkflowInstances: 40,
            stoppedWorkflowInstances: 30
        },
        {
            program: 'SC&I(OTS)',
            onboardedWorkflowInstances: 200,
            runningWorkflowInstances: 200,
            failedWorkflowInstances: 70,
            sucessfullWorkflowInstances: 130
        },
        {
            program: 'PCP',
            onboardedWorkflowInstances: 300,
            runningWorkflowInstances: 150,
            failedWorkflowInstances: 100,
            sucessfullWorkflowInstances: 50,
            stoppedWorkflowInstances: null
        },
        {
            program: 'SC&I seller crawls',
            onboardedWorkflowInstances: 200,
            runningWorkflowInstances: 200,
            failedWorkflowInstances: 150,
            sucessfullWorkflowInstances: 50,
            stoppedWorkflowInstances: 40
        },
        {
            program: 'SC&I tickets',
            onboardedWorkflowInstances: 300,
            runningWorkflowInstances: 150,
            failedWorkflowInstances: 120,
            sucessfullWorkflowInstances: 30,
            stoppedWorkflowInstances: 50
        },
        {
            program: 'Negative test Program',
            onboardedWorkflowInstances: null,
            runningWorkflowInstances: null,
            failedWorkflowInstances: null,
            sucessfullWorkflowInstances: null,
            stoppedWorkflowInstances: null
        }
    ]
}

export const getDashboardTestdata = () => {
    return new Promise<typeof dashboardTestdata>((resolve, reject) =>
        setTimeout(() => resolve(dashboardTestdata), 2000)
    )
}

export const workItemSummary = {
    summaryData: [
        {
            program: 'ACBP',
            avgWorkItems: 9,
            avgFailures: 1.5,
            humanErrors: 1.1,
            systemFailures: 0.4,
            successfulRuns: 0.6
        },
        {
            program: 'SIC',
            avgWorkItems: 4,
            avgFailures: 1,
            humanErrors: 0.8,
            systemFailures: 0.2,
            successfulRuns: 0.8
        },
        {
            program: 'SC&I(OTS)',
            avgWorkItems: 2,
            avgFailures: 0.5,
            humanErrors: 0.3,
            systemFailures: 0.2,
            successfulRuns: 0.9
        },
        {
            program: 'SC&I(OTS)',
            avgWorkItems: 2,
            avgFailures: 0.5,
            humanErrors: 0.3,
            systemFailures: 0.2,
            successfulRuns: 0.6
        },
        {
            program: 'PCP',
            avgWorkItems: 4,
            avgFailures: -1,
            humanErrors: 0.5,
            systemFailures: 0.5,
            successfulRuns: 0.6
        },
        {
            program: 'SC&I seller crawls',
            avgWorkItems: 4,
            avgFailures: 1,
            humanErrors: 0.7,
            systemFailures: 0.3,
            successfulRuns: 0.5
        },
        {
            program: 'SC&I tickets',
            avgWorkItems: 2,
            avgFailures: 0.5,
            humanErrors: 0.3,
            systemFailures: 0.2,
            successfulRuns: 0.7
        },
        {
            program: 'Negative test Program',
            avgWorkItems: null,
            avgFailures: null,
            humanErrors: null,
            systemFailures: null,
            successfulRuns: 0.5
        }
    ]
}

export const getWorkItemSummary = () => {
    return new Promise<typeof workItemSummary>((resolve, reject) =>
        setTimeout(() => resolve(workItemSummary), 1000)
    )
}

export const cycleTimeSummary = {
    summaryData: [
        {
            program: 'ACBP',
            tpninetyProgramCT: 50,
            tpninetyworkItemresolutionCT: 7,
            tpninetySystemfailureResolution: 5
        },
        {
            program: 'SIC',
            tpninetyProgramCT: 90,
            tpninetyworkItemresolutionCT: 8,
            tpninetySystemfailureResolution: 4
        },
        {
            program: 'SC&I(OTS)',
            tpninetyProgramCT: 35,
            tpninetyworkItemresolutionCT: 7,
            tpninetySystemfailureResolution: 3
        },
        {
            program: 'PCP',
            tpninetyProgramCT: 40,
            tpninetyworkItemresolutionCT: 6,
            tpninetySystemfailureResolution: 4
        },
        {
            program: 'SC&I seller crawls',
            tpninetyProgramCT: 25,
            tpninetyworkItemresolutionCT: 5,
            tpninetySystemfailureResolution: 5
        },
        {
            program: 'SC&I tickets',
            tpninetyProgramCT: 35,
            tpninetyworkItemresolutionCT: 4,
            tpninetySystemfailureResolution: 3
        },
        {
            program: 'Negative test Program',
            tpninetyProgramCT: null,
            tpninetyworkItemresolutionCT: null,
            tpninetySystemfailureResolution:null
        }
    ]
}
export const getCycleTimeSummary = () => {
    return new Promise<typeof cycleTimeSummary>((resolve, reject) =>
        setTimeout(() => resolve(cycleTimeSummary), 1500)
    )
}
